import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import "./layout.css"

import bitcoin from "../images/bitcoin.svg"
import visa from "../images/visa.svg"
import mastercard from "../images/mastercard.svg"
import amex from "../images/amex.svg"
import paypal from "../images/paypal.svg"

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <div className="container">
        <main>{children}</main>
        <footer>
          <div className="footer-top-mobile">
          <h2>SITEMAP</h2>
          <Link to="/">home</Link>
          <Link to="/services/">services</Link>
          <Link to="/contact/">contact us</Link>
              <h2>SOCIAL</h2>
              <a href="https://www.yelp.com/biz/j-and-c-remodeling-md-glen-burnie" target="_blank" rel="noopener noreferrer">yelp</a>
              <h2>CONTACT</h2>
              <p>jcremodelingmd@gmail.com</p>
              <p>(443) 623-2111</p>
              <h2>EMERGENCY</h2>
              <p>(443) 253-2425</p>
          </div>
          <div className="footer-top-desktop">
            <div className="footer-top-links">
              <h2>SITEMAP</h2>
              <Link to="/">home</Link>
          <Link to="/services/">services</Link>
          <Link to="/contact/">contact us</Link>
            </div>
            <div className="footer-top-links">
              <h2>SOCIAL</h2>
              <a href="https://www.yelp.com/biz/j-and-c-remodeling-md-glen-burnie" target="_blank" rel="noopener noreferrer">yelp</a>
            </div>
            <div className="footer-top-links">
              <h2>CONTACT</h2>
              <p>jcremodelingmd@gmail.com</p>
              <p>(443) 623-2111</p>
              <h2>EMERGENCY</h2>
              <p>(443) 253-2425</p>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom-left">
              <p>© Copyright J&C Remodeling INC. 2022</p>
            </div>
            <div className="footer-bottom-right">
              <p>We Accept:</p>
                <img src={bitcoin} alt="bitcoin" />
                <img src={visa} alt="visa" />
                <img src={mastercard} alt="mastercard" />
                <img src={amex} alt="amex" />
                <img src={paypal} alt="paypal" />
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
