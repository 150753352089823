import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.css"

import logo from "../images/navbar-logo.svg"
import phone from "../images/phone.svg"
import hamburger from "../images/hamburger.svg"
import closed from "../images/closed-burger.svg"



const Header = ({link1, link2, link3}) => {
  const [ nav, setNav ] = useState(false)

  return (
    <header>
    <div className="desktop-nav">
      <div className="desktop-nav-left">
        <img src={logo} alt="logo" />
        <Link to="/" activeStyle={{color: '#CB3100'}}>
          {link1}
        </Link>
        <Link to="/services/" activeStyle={{color: '#CB3100'}}>
          {link2}
        </Link>
        <Link to="/contact/" activeStyle={{color: '#CB3100'}}>
          {link3}
        </Link>
      </div>
      <div className="desktop-nav-right">
        <div className="desktop-phone-link">
          <img src={phone} alt="phone" />
          <p>(443) 623-2111</p>
        </div>
      </div>
    </div>

    <div className="mobile-nav">
      <div className="mobile-nav-top">
        <div className="mobile-nav-left">
          <img src={logo} alt="logo" />
        </div>
        <div className="mobile-nav-right">
          <div onClick={() => setNav(!nav)} className="nav-toggler">
            <img src={nav ? closed : hamburger} alt="toggler"/> 
          </div>
        </div>
        </div>
        <div style={{display: nav ? `flex` : `none`}} className="mobile-nav-bottom">
          <Link to="/" activeStyle={{color: '#CB3100'}}>
            {link1}
          </Link>
          <Link to="/services/" activeStyle={{color: '#CB3100'}}>
            {link2}
          </Link>
          <Link to="/contact/" activeStyle={{color: '#CB3100'}}>
            {link3}
          </Link>
        </div>
      </div>
  </header>
  )
}

Header.propTypes = {
  link1: PropTypes.string,
  link2: PropTypes.string,
  link3: PropTypes.string
}

Header.defaultProps = {
  link1: `home`,
  link2: `services`,
  link3: `contact us`
}

export default Header
